<template>
	<v-card flat v-if="initialized">
		<v-card-title>
			Sihirbaz Ayarları
		</v-card-title>
		<v-card-text>
			<v-row dense>
				<v-col cols="12" md="4">
					<v-row>
						<v-col cols="12" md="12" class="d-flex align-center">
							<v-checkbox
								v-model="cmsWizard.isAskDamageInfo"
								label="Hasar Bilgisi Göster"
								hide-details="auto"
							></v-checkbox>
						</v-col>
						<v-col cols="12" md="12">
							<v-checkbox v-model="cmsWizard.isExchangeActive" label="Takas Seçeneği" hide-details="auto"></v-checkbox>
						</v-col>
						<v-col cols="12">
							<v-checkbox
								v-model="cmsWizard.isColorMandatory"
								label="Araç Rengi Zorunlu"
								hide-details="auto"
							></v-checkbox>
						</v-col>
						<v-col cols="12">
							<v-checkbox
								v-model="cmsWizard.isCityMandatory"
								label="Şehir Seçimi Zorunlu"
								hide-details="auto"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<div class="d-flex justify-start py-5">
				<v-btn
					:loading="savePreloader"
					color="teal"
					class="text-capitalize"
					:dark="!$v.cmsWizard.$invalid"
					@click="save"
					:disabled="$v.cmsWizard.$invalid"
				>
					Değişiklikleri Kaydet
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { FETCH_SITE_SETTINGS, UPDATE_WIZARD_SETTING } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import wizardValidationMixin from '@/mixins/validations/backoffice/wizardValidationMixin';

export default {
	name: 'Wizard',
	mixins: [validationMixin, wizardValidationMixin],
	data() {
		return {
			savePreloader: false,
			cmsWizard: {
				isExchangeActive: false,
				isColorMandatory: false,
				isCityMandatory: false,
				isAskDamageInfo: false,
			},
		};
	},

	computed: {
		...mapGetters(['settings']),
		initialized() {
			return this.cmsSiteSettings !== null;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateWizardSetting: UPDATE_WIZARD_SETTING,
		}),
		save() {
			this.savePreloader = true;
			this.updateWizardSetting(this.cmsWizard)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
				});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsWizard = {
					isExchangeActive: company.cmpIsExchangeActive,
					isColorMandatory: company.cmpIsColorMandatory,
					isCityMandatory: company.cmpIsCityMandatory,
					isAskDamageInfo: company.cmpIsAskDamageInfo,
				};
			},
			deep: true,
		},
	},
};
</script>

<style></style>
