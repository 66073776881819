/* eslint-disable no-unused-expressions */
import {} from 'vuelidate/lib/validators';

const wizardValidationMixin = {
	validations: {
		cmsWizard: {},
	},
	methods: {
		// validation methods for this page
	},
};

export default wizardValidationMixin;
